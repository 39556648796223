<template>
  <div
    v-if="isReady"
    :key="timelineKey"
    class="flex flex-col w-full timeline-grid-area outline-0"
    @mousemove="onMouseMove"
    @mouseup="onMouseUp"
    @mouseleave="onMouseLeave"
  >
    <TimelineHeader
      :scale="scale"
      :client-scale="clientScale"
      :scroll="scroll"
      :is-playing="isPlaying"
      :current-timecode="currentTimecode"
      :current-time="currentTime"
      :duration-formatted="durationFormatted"
      :duration="duration"
      :duration-minimum="durationMinimum"
      :current-progresspc="progressPc"
      @timeline-to-pc="onTimelineToPc"
      @mouse-down="onMouseDown"
      @update-scale="onUpdateScale"
      @update-scroll="onUpdateScroll"
      @toggle-play="onTogglePlay"
    />

    <TimelineBody
      :layers="layers"
      :modules="modules"
      :duration="duration"
      :scale="scale"
      :scroll="scroll"
      :current-progresspc="progressPc"
      :playback-rate="playbackRate"
      :show-debug="showDebug"
      :active-module-id="activeModuleId"
      @mouse-down="onMouseDown"
      @view-module="onViewModule"
      @toggle-row="onToggleRow"
      @update-scroll="onUpdateScroll"
      @updated-client-scale="onUpdatedClientScale"
      @duplicate-layer="onDuplicateLayer"
      @enable-layer="onEnableLayer"
      @lock-layer="onLockLayer"
      @reorder-layers="onReorderLayers"
      @rename-layer="onRenameLayer"
      @trash-layer="onTrashLayer"
      @timeline-to-pc="onTimelineToPc"
      @drag-move-module="onDragMoveModule"
      @drag-move-module-end="onDragMoveModuleEnd"
      @drag-move-module-start="onDragMoveModuleStart"
      @new-module="onNewModule"
      @new-layer="onNewLayer"
      @increase-playbackrate="onIncreasePlaybackRate"
      @decrease-playbackrate="onDecreasePlaybackRate"
      @jump-to-time-relative="jumpToTimeRelative"
      @jump-to-prev="jumpToPrev"
      @jump-to-next="jumpToNext"
    />
  </div>
</template>
<script>
const TimelineHeader = () => import('./TimelineHeader')
const TimelineBody = () => import('./TimelineBody')
const ModuleOverview = () => import('./modules/ModuleOverview')
const LayerRenameModal = () => import('@modals/LayerRename')
const LayerDeleteModal = () => import('@modals/LayerDelete')

export default {
  name: 'TimelinePanel',

  components: {
    TimelineHeader,
    TimelineBody
  },

  props: {
    viewer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activecallback: null,
      scale: '2.5',
      clientScale: 1,
      scroll: 0,
      scrollPc: 0,
      showDebug: false,
      activeModuleId: null
    }
  },

  computed: {
    timelineKey () {
      return 'timeline-duration-' + this.duration
    },

    previztimeline() {
      if (this.hasTimeline) return this.viewer.core.sequence.timeline
      return null
    },

    hasTimeline() {
      if (!this.viewer) return false
      if (!this.viewer.core) return false
      if (!this.viewer.core.sequence) return false
      if (!this.viewer.core.sequence.timeline) return false
      return true
    },

    hasChanges() {
      if (!this.isReady) return false
      return this.previztimeline.hasChanges
    },

    lastChange() {
      if (!this.isReady) return false
      return this.previztimeline.lastChange
    },

    playbackRate() {
      if (!this.isReady) return 1
      return Number.parseFloat(this.previztimeline.playbackRate)
    },

    layers() {
      if (!this.isReady) return []
      return this.previztimeline.layers
    },

    modules() {
      if (!this.isReady) return []
      return this.previztimeline.modules
    },

    isReady() {
      return this.previztimeline !== null
    },

    durationFormatted() {
      if (!this.isReady) return '00:00'
      return this.previztimeline.durationFormatted
    },

    durationMinimum() {
      if (!this.isReady) return 10
      return this.previztimeline.durationMinimum
    },

    currentTimecode() {
      if (!this.isReady) return '00:00'
      return this.previztimeline.currentTimecode
    },

    currentTime() {
      if (!this.isReady) return 0
      return this.previztimeline.currentTime
    },

    playerState() {
      if (this.isPlaying) return 'playing'
      return 'paused'
    },

    isPlaying() {
      if (this.isReady) {
        return this.previztimeline.isPlaying
      }
      return false
    },

    progressPc() {
      if (this.isReady) {
        return this.previztimeline.progressPc
      }
      return false
    },

    duration() {
      if (this.isReady) {
        return this.previztimeline.duration
      }
      return 0
    }
  },

  watch: {
    hasChanges(val) {
      if (val === true) {
        this.$emit('timeline-changed')
      }
    },
    lastChange(val) {
      if (val !== null) {
        this.$emit('timeline-changed')
      }
    }
  },

  mounted() {
    this.attachBusListeners()
  },

  methods: {
    onUpdatedClientScale(value) {
      this.clientScale = value
    },

    onIncreasePlaybackRate() {
      this.previztimeline.increasePlaybackRate()
    },

    onDecreasePlaybackRate() {
      this.previztimeline.decreasePlaybackRate()
    },

    jumpToTime(value) {
      this.previztimeline.jumpToTime(value)
    },

    jumpToTimeRelative(value) {
      let val = Number.parseFloat(this.currentTime) + value

      if (val < 0) val = 0
      if (val > this.duration) val = this.duration

      this.jumpToTime(val)
    },

    jumpToNext() {
      this.jumpToTime(this.duration)
    },

    jumpToPrev() {
      this.jumpToTime(0)
    },

    attachBusListeners() {
      this.$bus.$on('timeline:new-module', this.onNewModule)
      this.$bus.$on('timeline:delete-module', this.onTrashModule)
      this.$bus.$on('layer:rename', this.onRenameLayerCallback)
      this.$bus.$on('layer:delete', this.onTrashLayerCallback)
      this.$bus.$on('timeline:update-duration', this.onUpdateDurationCallback)
      this.$bus.$on('editor:inspector:clearactive', this.onClearActive)
      this.$bus.$on('editor:inspector:makeactive', this.onMakeActive)
    },

    onMakeActive(key) {
      if (key === undefined || key === null) this.activeModuleId = null
      else {
        key = key.toString()
        if (key.startsWith('module-')) {
          this.activeModuleId = key.replace('module-', '')
        } else {
          this.onClearActive()
        }
      }
    },

    onClearActive() {
      this.activeModuleId = null
    },

    onNewLayer(event) {
      this.newLayer()
    },

    newLayer() {
      let name = 'Layer'
      if (this.layers.length > 0) {
        name = name + ' ' + (this.layers.length + 1)
      }

      let data = {
        name: name
      }

      return this.previztimeline.addLayer(data)
    },

    onNewModule(event) {
      if (event.file) {
        let mod = this.createModule(event)
        this.previztimeline.updateModuleAssetWithFile(mod, event.file)
        this.onViewModule(mod.id)
      } else if (event.assetId) {
        let assetId = event.assetId
        this.$store
          .dispatch('assets/loadAsset', { id: assetId })
          .then((asset) => {
            let mod = this.createModule(event, asset)
            this.previztimeline.updateModuleAsset(mod, asset)
            if(event.target){
              this.previztimeline.editModuleTargets(mod, [event.target])
            }
            this.onViewModule(mod.id)
          })
      } else {
        let mod = this.createModule(event)
        this.onViewModule(mod.id)
      }
    },

    createModule(event, asset) {
      let layer = null
      if (event.layerId === undefined) {
        // No layer defined. Create a new one.
        layer = this.newLayer()
      } else {
        layer = this.layers.find((layer) => layer.id === event.layerId)
      }
      if (layer === undefined || layer === null) return

      let start = event.x
      if (start === undefined) {
        start = this.currentTime
      }

      let duration = 10 // @todo - should default to length of asset if that's set
      return this.previztimeline.attemptCreateModule(layer, start, duration)
    },

    onTrashModule(event) {
      let mod = event.mod

      this.previztimeline.deleteModule(mod)
    },

    onDragMoveModuleStart(event) {
      let mod = event.mod
      let x = event.x

      this.attemptModuleResize(mod, mod.layer, x, 'start')
    },

    onDragMoveModuleEnd(event) {
      let mod = event.mod
      let x = event.x

      this.attemptModuleResize(mod, mod.layer, x, 'end')
    },

    onDragMoveModule(event) {
      // get the module first
      let mod = this.modules.find((row) => row.id === event.moduleId)
      if (mod === undefined) return

      let layer = null
      if (event.layerId === undefined) {
        // No layer defined. Create a new one.
        layer = this.newLayer()
      } else {
        layer = this.layers.find((layer) => layer.id === event.layerId)
      }
      if (layer === undefined || layer === null) return

      // Check validity of the move
      this.attemptModuleMove(mod, layer, event.x)
    },

    attemptModuleResize(mod, layer, x, adjustmode) {
      return this.previztimeline.attemptModuleResize(mod, layer, x, adjustmode)
    },

    attemptModuleMove(mod, layer, x, movemode) {
      return this.previztimeline.attemptModuleMove(mod, layer, x, movemode)
    },

    onTimelineToPc(pc) {
      if (this.isReady && !isNaN(pc)) {
        let time = this.duration * pc
        time = time.toFixed(2)
        this.jumpToTime(time)
      }
    },

    onTogglePlay() {
      if (this.isReady) this.previztimeline.togglePlay()
    },

    // onClearModule () {
    //   this.$bus.$emit('editor:sidebar:clear')
    // },

    onViewModule(id) {
      // this.activeModuleId = id

      let mod = this.modules.find((row) => row.id === id)

      if (mod === undefined) {
        this.$bus.$emit('editor:sidebar:clear')
      } else {
        this.showModuleModal('module', mod)
      }
    },

    showModuleModal(type, mod) {
      this.previztimeline.pause()

      this.$bus.$emit('editor:sidebar', {
        component: ModuleOverview,
        props: {
          type: type,
          mod: mod,
          key: 'module-' + mod.id,
          timeline: this.previztimeline
        }
      })
    },

    onToggleRow(layerId) {
      console.log('@todo - handle on toggle row')
    },

    onMouseMove(event) {
      if (this.activecallback !== null) this.activecallback(event.movementX)
    },

    onMouseDown(callback) {
      this.activecallback = callback
    },

    onMouseUp() {
      this.activecallback = null
    },

    onMouseLeave() {
      this.activecallback = null
    },

    onUpdateScale(value) {
      this.scale = value
    },

    onUpdateScroll(value) {
      this.scroll = Number.parseFloat(value)
    },

    onDuplicateLayer(layerId) {
      console.log('@todo - handle duplicate layer ')
    },

    onUpdateDurationCallback(newDuration) {
      this.previztimeline.duration = newDuration
    },

    onTrashLayer(layerId) {
      let layer = this.layers.find((layer) => layer.id === layerId)
      if (layer === undefined) return

      this.$modal.show(
        LayerDeleteModal,
        {
          layer: layer
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    },

    onReorderLayers(orders) {
      this.previztimeline.reorderLayers(orders)
    },

    onEnableLayer(layerId) {
      let layer = this.layers.find((layer) => layer.id === layerId)
      if (layer === undefined) return

      let props = {
        enabled: !layer.enabled
      }
      this.updateLayer(layer, props)
    },

    onLockLayer(layerId) {
      let layer = this.layers.find((layer) => layer.id === layerId)
      if (layer === undefined) return

      let props = {
        locked: !layer.locked
      }
      this.updateLayer(layer, props)
    },

    onRenameLayer(layerId) {
      let layer = this.layers.find((layer) => layer.id === layerId)
      if (layer === undefined) return

      this.$modal.show(
        LayerRenameModal,
        {
          layer: layer
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    },

    onRenameLayerCallback(data) {
      let props = {
        name: data.name
      }
      this.updateLayer(data.layer, props)
    },

    onTrashLayerCallback(data) {
      this.previztimeline.deleteLayer(data.layer)
    },

    updateLayer(layer, props) {
      this.previztimeline.updateLayer(layer, props)
    }
  }
}
</script>
